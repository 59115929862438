// import Footer from "./components/Footer";
// import Header from "./components/Header";
// import Logo from "./components/Logo";
// import Pointe from "./components/Pointe";
// import Projects from "./components/Projects";
import TripaliumLogo from "./components/svgComponent/TripaliumLogo";
import "./css/App.css";

function App() {
  return (
    <div className="App">
      <div className="container">
        <div className="top">
          <TripaliumLogo />
          <div className="contacts">
            <div className="profile-info">
              <span className="infos-column">
                <div className="name">Paul Gilbert</div>
                <div className="job">Designer</div>
              </span>
              <span className="infos-column">
                <div className="email">paul@tripalium-studio.com</div>
                <div className="phone">+33 (0)6 22 07 22 31</div>
              </span>
            </div>
            <div className="profile-info">
              <span className="infos-column">
                <div className="name">Colin Schmitt</div>
                <div className="job">Full Stack Developer</div>
              </span>
              <span className="infos-column">
                <div className="email">colin@tripalium-studio.com</div>
                <div className="phone">+1 (438) 509 9595</div>
              </span>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="text">Website is under construction</div>
        </div>
      </div>
    </div>
  );
}

export default App;
